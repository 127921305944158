s<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="管理相册" style="padding: 20px 10px;" />
		<div class="top" v-if="!isBatch">
			<div class="top-image">
				<img :src="$store.state.IMG_URL+'/'+album.thumb" />
			</div>
			<div class="top-container">
				<div class="top-name">
					<h3>{{album.title}}</h3>
					<span>共{{count}}张</span>
				</div>
				<div class="top-edit">
					<el-button type="primary" size="mini" @click="handleEditAlbumName">修改名称</el-button>
					<el-button type="primary" size="mini" @click="isFileDiaShow=true">上传图片</el-button>
					<el-dropdown @command="handleCommand">
						<el-button type="primary" size="mini">
							更多菜单<i class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item :command="1">批量移动</el-dropdown-item>
							<el-dropdown-item :command="2">批量删除</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</div>
		<div class="batch" v-else>
			<label class="all">
				<input type="checkbox" v-model="allChecked" @change="handleAllChenge">
				<div class="checkbox-box">
					<i class="el-icon-check"></i>
				</div>
				<span>本页全选</span>
			</label>
			<p class="all-length">已选择<span>0</span>张</p>
			<div class="batch-btn">
				<el-button size="mini" type="primary" @click="handleAlbumShow">移动相册</el-button>
				<el-button size="mini" type="danger" @click="handleAllDel">删除</el-button>
				<el-button size="mini" @click="isBatch=false">返回</el-button>
			</div>
		</div>
		<ul class="list">
			<li v-for="(item,index) in list" :key="item.pic_id">
				<label class="checkbox-wrap" v-if="isBatch">
					<input type="checkbox" v-model="item.isChecked">
					<div class="checkbox-box">
						<i class="el-icon-check"></i>
					</div>
				</label>
				<div class="list-img">
					<img :src="$store.state.IMG_URL+'/'+item.thumb" alt="">
					<div class="image-edit">
						<el-button type="danger" size="mini" @click="handleDet(item.pic_id)">查看</el-button>
						<el-button type="danger" size="mini" @click="handleMove(item.pic_id)">移动</el-button>
						<el-button type="danger" size="mini" @click="handleDel(item.pic_id)">删除</el-button>
					</div>
				</div>
				<div class="list-name">
					<span class="name-text" @click="list[index].isEdit=true">{{item.title}}</span>
					<div class="name-edit" v-show="item.isEdit">
						<input type="text" v-model="item.title">
						<div class="name-edit-btn">
							<el-button type="primary" size="mini" @click="handleEditName(index)">确认</el-button>
							<el-button size="mini" @click="list[index].isEdit=false">取消</el-button>
						</div>
					</div>
				</div>
				<div class="list-time">{{item.time}}</div>
			</li>
		</ul>
		<div class="page">
			<el-pagination background layout="prev, pager, next" :total="count" :page-size="24" hide-on-single-page
				:current-page="curPage" @current-change="currentChange" />
		</div>
		<fileDialog :albumData="albumData" :show="isFileDiaShow" :close.sync="isFileDiaShow" @eventSubmit="getData()"
			:echoId="album.id" active="2" />
		<el-dialog title="选择相册" :visible.sync="albumListShow">
			<ul class="album-list">
				<li v-for="item in albumData.list" :key="item.id">
					<label class="checkbox-wrap">
						<input type="radio" :value="item.id" v-model="moveId">
						<div class="checkbox-box">
							<i class="el-icon-check"></i>
						</div>
					</label>
					<div class="album-img">
						<el-image :src="$store.state.IMG_URL+'/'+item.thumb">
							<div slot="error" class="image-slot">
								<i class="el-icon-picture-outline"></i>
							</div>
						</el-image>
					</div>
					<p>{{item.title}}</p>
				</li>
			</ul>
			<span slot="footer" class="dialog-footer">
				<el-button size="mini" @click="albumListShow=false">取 消</el-button>
				<el-button type="primary" size="mini" @click="handleAllMove">确 定</el-button>
			</span>
		</el-dialog>
		<detDialog :picId="picId" :show="isDetShow" :close.sync="isDetShow" :albumId="album.id" />
	</div>
</template>

<script>
	import fileDialog from '@/components/plug/fileDialog'
	import detDialog from './components/detDialog'
	export default {
		data() {
			return {
				list: [],
				count: 0,
				curPage: 1,
				albumData: {
					list: []
				},
				album: {},
				isFileDiaShow: false,
				isDetShow: false,
				isBatch: false,
				allChecked: false,
				moveId: '',
				albumListShow: false,
				temId: 0,
				picId: 0
			}
		},
		created() {
			this.getData()
			this.$axios.post('/albumList').then(data => {
				this.albumData = data
			})
		},
		methods: {
			handleEditAlbumName() {
				this.$prompt('请输入新名称', '修改名称', {
					inputValue: this.album.title,
					closeOnClickModal: false
				}).then(({
					value
				}) => {
					this.$axios.post('/editAlbumTitle', {
						title: value,
						album_id: this.album.id
					}).then(data => {
						if (data.code == 41002) {
							this.$message.error('修改失败')
							return
						}
						this.$message.success('修改成功')
						this.album.title = value
					})
				}).catch(() => {})
			},
			currentChange(cur) {
				this.curPage = cur
				this.getData()
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			},
			handleEditName(index) {
				this.$axios.post('/editPictures', {
					title: this.list[index].title,
					pic_id: this.list[index].pic_id
				}).then(data => {
					if (data.code == 41002) {
						this.$message.error('修改失败')
						return
					}
					this.$message.success('修改成功')
					this.list[index].isEdit = false
				})
			},
			handleCommand(command) {
				this.isBatch = true
			},
			handleAllChenge() {
				this.list.forEach(item => item.isChecked = this.allChecked)
			},
			handleAlbumShow() {
				if (!this.checkedList.length) {
					this.$message.error('请先选择图片')
					return
				}
				this.albumListShow = true
			},
			handleAllMove() {
				if (!this.moveId) {
					this.$message.error('请先选择相册')
					return
				}
				this.$axios.post('/mvPictures', {
					album_id: this.moveId,
					pic_id: this.isBatch ? this.checkedList : this.temId
				}).then(data => {
					this.$message.success('移动成功')
					this.albumListShow = false
					this.getData()
				})
			},
			handleAllDel() {
				if (!this.checkedList.length && this.isBatch) {
					this.$message.error('请先选择图片')
					return
				}
				this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.post('/delPictures', {
						pic_id: this.isBatch ? this.checkedList : this.temId
					}).then(data => {
						this.$message.success('删除成功')
						this.getData()
					})
				}).catch(() => {})
			},
			handleMove(id) {
				this.temId = [id]
				this.albumListShow = true
			},
			handleDel(id) {
				this.temId = [id]
				this.handleAllDel()
			},
			handleDet(id) {
				this.picId = id
				this.isDetShow = true
			},
			getData() {
				this.$axios.post('/albumPicList', {
					album_id: this.$route.query.id,
					page: this.curPage
				}).then(data => {
					data.list.forEach(item => {
						item.isEdit = false
						item.isChecked = false
					})
					this.list = data.list
					this.count = data.count
					this.album = data.album
				})
			}
		},
		computed: {
			checkedList() {
				let list = []
				this.list.forEach(item => {
					if (item.isChecked) {
						list.push(item.pic_id)
					}
				})
				return list
			}
		},
		components: {
			fileDialog,
			detDialog
		}
	}
</script>

<style scoped>
	.o-wrap{
		width: 1600px;
		background-color: #fff;
	}
	.top {
		padding: 20px 0;
		margin-left: 20px;
		border-top: 1px solid #e6e6e6;
		display: flex;
	}

	.top-image {
		width: 80px;
		height: 80px;
		padding: 2px;
		border: 1px solid #e6e6e6;
	}

	.top-image img {
		width: 100%;
		height: 100%;
	}

	.top-container {
		margin-left: 20px;
	}

	.top-name {
		height: 30px;
		display: flex;
		align-items: flex-end;
	}

	.top-name h3 {
		font-size: 16px;
		font-weight: 500;
		color: #333;
	}

	.top-name span {
		margin-left: 15px;
		color: #b2b2b2;
	}

	.top-edit {
		margin-top: 10px;
	}

	.top-edit .el-dropdown {
		margin-left: 10px;
	}

	.list {
		width: 1600px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		color: #333;
	}

	.list li {
		width: 166px;
		border: 1px solid #e6e6e6;
		margin: 0px 10px;
		position: relative;
	}

	.checkbox-wrap {
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .3);
		z-index: 2;
		left: 0;
		top: 0;
	}

	.checkbox-wrap input {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 3;
		cursor: pointer;
		opacity: 0;
	}

	.checkbox-box {
		width: 18px;
		height: 18px;
		background: #fff;
		position: absolute;
		margin: 10px 0 0 10px;
		border-radius: 3px;
		border: 1px solid #0f69dc;
	}

	.checkbox-box i {
		width: 100%;
		height: 100%;
		background: #0f69dc;
		font-size: 16px;
		color: #fff;
		align-items: center;
		justify-content: center;
		font-weight: 700;
		display: none;
	}

	.checkbox-wrap input:checked+.checkbox-box i {
		display: flex;
	}

	.list-img {
		position: relative;
		height: 166px;
	}

	.list-img img {
		width: 100%;
		height: 100%;
	}

	.list-img:hover .image-edit {
		background: rgba(0, 0, 0, .5);
	}

	.list-img :hover .el-button {
		opacity: 1;
	}

	.image-edit {
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0);
		top: 0;
		left: 0;
		transition: background .3s ease-in-out;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.image-edit .el-button {
		opacity: 0;
		margin-left: 0;
		margin: 5px 0;
		transition: opacity .3s ease-in-out;
	}

	.list-name {
		margin: 5px 10px;
		font-size: 14px;
		position: relative;
	}

	.name-text {
		height: 30px;
		line-height: 30px;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding: 0 5px;
		border: 1px solid transparent;
		border-radius: 5px;
		cursor: pointer;
	}

	.name-text:hover {
		border: 1px solid #e6e6e6;
	}

	.name-edit {
		position: absolute;
		top: 0;
		left: 0;
		background: #fff;
		width: 100%;
	}

	.name-edit input {
		height: 32px;
		line-height: 32px;
		width: 100%;
		border: 1px solid #409EFF;
		text-indent: 5px;
		border-radius: 5px;
		width: 100%;
		box-sizing: border-box;
	}

	.name-edit-btn {
		display: flex;
		margin-top: 5px;
		justify-content: space-around;
	}

	.list-time {
		padding: 10px 15px;
		color: #909399;
		border-top: 1px solid #e6e6e6;
	}

	.page {
		padding: 20px;
		text-align: center;
	}

	.batch {
		padding: 20px 50px 20px 0;
		display: flex;
		align-items: center;
		font-size: 14px;
		align-items: center;
	}

	.all {
		position: relative;
		display: flex;
		align-items: center;
	}

	.all .checkbox-box {
		position: static;
		margin: 0;
	}

	.batch input {
		position: absolute;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	.batch input:checked+.checkbox-box i {
		display: flex;
	}

	.all span {
		margin-left: 5px;
	}

	.all-length {
		margin-left: 50px;
	}

	.all-length span {
		color: #0f69dc;
		padding: 0 2px;
	}

	.batch-btn {
		display: flex;
		margin-left: auto;
		align-items: center;
	}

	.album-list {
		display: flex;
		flex-wrap: wrap;
	}

	.album-list li {
		padding: 6px;
		border: 1px solid #ccc;
		margin-left: 15px;
		margin-bottom: 15px;
		position: relative;
	}

	.album-list .album-img {
		width: 120px;
		height: 120px;
	}

	.album-list p {
		height: 30px;
		line-height: 30px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.el-image {
		width: 100%;
		height: 100%;
	}

	.el-image>>>.image-slot {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #DCDFE6;
	}

	.image-slot i {
		font-size: 30px;
	}

	.album-list .checkbox-wrap {
		background: transparent;
	}
</style>
