<template>
	<el-dialog :visible.sync="dialogVisible" :before-close="()=>$emit('update:close',false)" width="770px" top="15px">
		<div class="det-banner">
			<img :src="$store.state.IMG_URL+'/'+list[act].thumb" alt="">
			<div class="prev el-icon-arrow-left" @click="handleImgPrev"></div>
			<div class="next el-icon-arrow-right" @click="handleImgNext"></div>
		</div>
		<div class="det-list">
			<div class="prev el-icon-arrow-left" v-show="this.page>1"></div>
			<div class="next el-icon-arrow-right" @click="handleListNext" v-if="this.max_page>this.page"></div>
			<ul>
				<li v-for="(item,index) in list" :key="item.pic_id" :class="index==act?'active':''"
					@click="handleSwitch(index)">
					<img :src="$store.state.IMG_URL+'/'+item.thumb" alt="">
				</li>
			</ul>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				list: [{
					thumb: ''
				}],
				page: 1,
				act: 0,
				pic_id: 0,
				max_page: 1
			}
		},
		methods: {
			handleSwitch(index) {
				this.act = index
			},
			handleImgPrev() {
				if (this.act > 0) {
					this.act--
				} else {
					this.act = this.list.length - 1
				}
			},
			handleImgNext() {
				if (this.act < this.list.length - 1) {
					this.act++
				} else {
					this.act = 0
				}
			},
			handleListNext() {
				this.page++
				this.act = 0
				this.getList()
			},
			getList() {
				this.$axios.post('/albumPicList', {
					album_id: this.albumId,
					pic_id: this.pic_id,
					page: this.page,
					limit: 7
				}).then(data => {
					this.list = data.list
					this.max_page = Math.ceil(data.count / 7)
					this.list.forEach((item, index) => {
						if (item.pic_id == this.pic_id) {
							this.act = index
						}
					})
					if (!this.act) {
						this.act = 0
					}
				})
			}
		},
		watch: {
			picId(val) {
				this.page = 1
				this.pic_id = val
				this.getList()
			},
			show(val) {
				this.dialogVisible = val
			}
		},
		props: ['picId', 'show', 'albumId']
	}
</script>

<style scoped>
	.det-banner {
		width: 730px;
		height: 730px;
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
	}

	.det-banner img {
		max-width: 730px;
		max-height: 730px;
	}

	.next,
	.prev {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 50px;
		height: 80px;
		background: rgba(0, 0, 0, .5);
		color: #fff;
		font-size: 50px;
		text-align: center;
		line-height: 80px;
		cursor: pointer;
	}

	.prev {
		left: 0;
	}

	.next {
		right: 0;
	}

	.det-list {
		width: 730px;
		position: relative;
		margin-top: 15px;
	}

	.det-list .next,
	.det-list .prev {
		width: 30px;
		height: 86px;
		font-size: 30px;
		background: transparent;
		color: #333;
	}

	.det-list ul {
		display: flex;
		margin: 0 30px;
	}

	.det-list li {
		width: 80px;
		height: 80px;
		margin: 0 5px;
		border: 3px solid #F2F6FC;
	}

	.det-list li.active {
		border: 3px solid #909399;
	}

	.det-list img {
		width: 80px;
		height: 80px;
	}
</style>
